<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Advertising from '@/services/Advertising'
import Swal from "sweetalert2";
import modalAddSponsorAttributes from "@/components/modals/advertising/modalAddSponsorAttributes";
import modalAddSponsorMedia from "@/components/modals/advertising/modalAddSponsorMedia";
import modalEditSponsor from "@/components/modals/advertising/modalEditSponsor";
import TabSponsorMedia from "@/components/tabs/tabSponsorMedia";
import TabSponsorAdvertisements from "@/components/tabs/tabSponsorAdvertisements";

/**
 * Sponsor component
 */
export default {
    components: { Layout, PageHeader, TabSponsorAdvertisements, TabSponsorMedia, modalAddSponsorAttributes, modalAddSponsorMedia, modalEditSponsor },
    page: {
        title: "Sponsors",
        meta: [
            {
                name: "description",
                content: appConfig.description,
            },
        ],
    },
    data() {
        return {
            blurConfig: {
                isBlurred: false, // activate and deactivate based on a boolean value and also provide a config
                opacity: 0.1,
                filter: 'blur(1.5px)',
                transition: 'all .3s linear'
            },
            title: "Sponsor Details",
            items: [
                {
                    text: "Advertising"
                },
                {
                    text: "Sponsor",
                    active: true,
                },
            ],
            sponsor_id: this.$route.params.id,
            sponsorData: {
                name: null,
                active: null,
                logo: null,
                file: '',
                url: null,
                description: null,
                created: null,
                attributes: null,
                media: null
            },
            error: null,
            tryToEdit: false,
            tryingToSubmit: false,
            toggleProfile: false,
            showLoader:false
        };
    },
    async mounted() {
        this.getSponsor()
    },
    methods: {

        getSponsor(){
            this.showLoader = true;
            Advertising.getSponsorById(this.sponsor_id)
                .then(response => {
                    if(!this.sponsorData){
                        this.$router.push("/advertising/sponsors")
                    }
                    this.sponsorData = response.data.data[0];
                })
                .catch(error => {
                    this.error = error.response.data.error ? error.response.data.error : "";
                })
                .finally(() => {
                    this.showLoader = false;
                })
        },

        confirmRemoveAttribute(attribute) {
            Swal.fire({
                title: "Are you sure?",
                //text: "This Match will no longer be featured !",
                icon: "warning",
                showCancelButton: true,
                cancelButtonColor: "#f46a6a",
                confirmButtonColor: "#34c38f",
                confirmButtonText: "Delete!",
            }).then((result) => {
                if (result.value) {
                    Advertising.removeAttribute(this.sponsor_id, attribute)
                        .then((response) => {
                            const res = response.data.data[0].id ? response.data.data[0].id : false;
                            const error = response.data.error ? response.data.error : '';
                            if(res && error==''){
                                this.getSponsor()
                                this.successmsg("Attribute Successfully Removed")
                            }else{
                                this.failedmsg("Attribute Removal Failed")
                            }
                        })
                        .catch(error => {
                            this.failedmsg('Failed!', error)
                        });
                }
            });
        },

        deleteSponsor() {
            Swal.fire({
                title: "Are you sure?",
                //text: "This Match will no longer be featured !",
                icon: "warning",
                showCancelButton: true,
                cancelButtonColor: "#f46a6a",
                confirmButtonColor: "#34c38f",
                confirmButtonText: "Delete!",
            }).then((result) => {
                if (result.value) {
                    Advertising.removeSponsor(this.sponsor_id)
                        .then((response) => {
                            const res = response.data.data ? response.data.data : false;
                            const error = response.data.error ? response.data.error : '';
                            if(res && error==''){
                                this.successmsg("Attribute Successfully Removed")
                                this.$router.push("/advertising/sponsors")

                            }else{
                                this.failedmsg("Sponsor Removal Failed")
                            }
                        })
                        .catch(error => {
                            this.failedmsg('Failed!', error)
                        });
                }
            });
        },

        modalAddAttributes() {
            this.$bvModal.show("add_sponsor_attributes");
        },

        modalAddSponsorMedia() {
            this.$bvModal.show("add_sponsor_media");
        },

        callModalSponsorEdit() {
            this.$bvModal.show("sponsor_edit");
        },

        callModalAddAttributes() {
            this.$bvModal.show("add_sponsor_attributes");
        },

        callModalAddSponsorMedia() {
            this.$bvModal.show("add_sponsor_media");
        },

        toggle() {
            this.toggleProfile = !this.toggleProfile
        },
    },
    
};
</script>

<template>
<Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row mb-4">
        <div v-if="!toggleProfile" class="col-xl-4">
            <div class="card h-100">
                <div class="card-body" v-if="!showLoader">
                    <div class="text-center">

                        <b-dropdown class="float-end" variant="white" right menu-class="dropdown-menu-end" toggle-class="font-size-16 text-body p-0">
                            <template v-slot:button-content>
                                <i class="uil uil-ellipsis-v"></i>
                            </template>
                            <a class="dropdown-item" href="javascript:void(0);" @click="callModalSponsorEdit()">Edit</a>
                            <a class="dropdown-item" href="javascript:void(0);" @click="callModalAddAttributes()">Add Attributes</a>
                            <a class="dropdown-item" href="javascript:void(0);" @click="callModalAddSponsorMedia()">Add Media</a>
                            <a class="dropdown-item" href="javascript:void(0);" @click="deleteSponsor()">Remove</a>
                        </b-dropdown>
                        <i class="uil uil-angle-left float-start font-size-20 text-body btn p-0" @click="toggle"></i>
                        <div class="clearfix"></div>
                        <div class="">
                            <img v-if="sponsorData.logo" :src="sponsorData.logo" alt class="w-50 img-thumbnail" />
                            <img v-else src="@/assets/images/users/avatar-generic.png" alt class="avatar-lg rounded-circle img-thumbnail" />
                        </div>
                        <h5 class="mt-3 mb-1">{{ sponsorData.name }}</h5>

                        <div class="mt-4">
                            <div v-html="format_status(sponsorData.active ? 'active' : 'inactive')"></div>
                        </div>
                    </div>

                    <hr class="my-2" />

                        <div class="table-responsive mt-4 mb-0">
                            <h5>Sponsor Information</h5>
                            <div class="mt-3">
                                <p class="mb-2 text-strong">Description :</p>
                                <p class="mb-2">{{sponsorData.description}}</p>
                            </div>
                            <div class="mt-3">
                                <p class="mb-2 text-strong">Sponsor Url :
                                    <span class="text-muted float-end" v-if="sponsorData.url && sponsorData.url.length">
                                        <a :href="sponsorData.url">{{sponsorData.url}}</a>
                                    </span>
                                    <span class="text-muted float-end" v-else>/</span>
                                </p>
                            </div>

                            <div class="mt-3">
                                <p class="mb-2 text-strong">Attributes :</p>
                                <p v-for="attribute in sponsorData.attributes" :key="attribute.attribute" class="font-size-16 mb-2 hidden_actions d-flex justify-content-between">
                                    <span>{{attribute.attribute}}: {{attribute.value}}</span>

                                    <span>
                                          <a href="javascript:void(0);" title="Remove attribute" class="float-end text-danger" @click="confirmRemoveAttribute(attribute.attribute)">
                                            <i class="uil uil-trash-alt font-size-18"></i>
                                        </a>
                                    </span>

                                </p>
                            </div>
                            <hr>

                            <b-button class="mb-2 d-block m-auto mwb-11" variant="primary" title="Add Sponsor Attributes" @click="callModalAddAttributes()">
                                <i class="uil-plus-circle"></i> Add Attributes
                            </b-button>
                            <b-button class="mb-2 d-block m-auto mwb-11" variant="primary" title="Add Sponsor Media" @click="callModalAddSponsorMedia()">
                                <i class="uil-images"></i> Add Media
                            </b-button>

                            <div class="mt-5">
                                <p class="mb-1">Created : {{ sponsorData.hasOwnProperty('created') ? sponsorData.created : null  }}</p>
                            </div>
                        </div>

                </div>
                <div class="card-body d-flex align-items-center justify-content-center" v-else>
                    <b-spinner large></b-spinner>
                </div>
            </div>
        </div>
        <div v-else class="col-xl-2">
            <div class="card h-100">
                <div class="card-body">
                    <div class="text-center">
                        <b-dropdown class="float-end" variant="white" right menu-class="dropdown-menu-end" toggle-class="font-size-16 text-body p-0">
                            <template v-slot:button-content>
                                <i class="uil uil-ellipsis-v"></i>
                            </template>
                            <a class="dropdown-item" href="javascript:void(0);" @click="callModalSponsorEdit()" >Edit</a>
                            <a class="dropdown-item d-done" href="javascript:void(0);" >Add Attributes</a>
                        </b-dropdown>
                        <i class="uil uil-angle-right float-start font-size-20 text-body btn p-0" @click="toggle"></i>
                        <div class="clearfix"></div>
                        <div class="">
                            <img v-if="sponsorData.logo" :src="sponsorData.logo" alt class="w-50 img-thumbnail" />
                            <img v-else src="@/assets/images/users/avatar-generic.png" alt class="avatar-lg rounded-circle img-thumbnail" />
                        </div>
                        <h5 class="mt-3 mb-1">{{ sponsorData.name }}</h5>

                        <div class="mt-4">
                            <div class="font-size-18" v-html="format_status(sponsorData.active ? 'active' : 'inactive')"></div>
                        </div>
                    </div>
                    <hr class="my-2" />

                    <div class="table-responsive mt-4 mb-0">
                        <b-button class="mb-2 d-block m-auto mwb-11" variant="primary" title="Add Sponsor Attributes" @click="callModalAddAttributes()">
                            <i class="uil-plus-circle"></i> Add Attributes
                        </b-button>
                        <b-button class="mb-2 d-block m-auto mwb-11" variant="primary" title="Add Sponsor Media" @click="callModalAddSponsorMedia()">
                            <i class="uil-images"></i> Add Media
                        </b-button>
                    </div>
                </div>
            </div>
        </div>

        <div :class="!toggleProfile ? 'col-xl-8':'col-xl-10'">
            <div class="card mb-0">
                <b-tabs content-class="p-4" justified class="nav-tabs-custom">
                    <b-tab>
                        <template v-slot:title>
                            <i class="uil uil-images font-size-20"></i>
                            <span class="d-none d-sm-block">Media</span>
                        </template>
                        <div class="row">
                            <TabSponsorMedia :media="sponsorData.media" @onRefresh="getSponsor()" />
                        </div>
                    </b-tab>
                    <b-tab>
                        <template v-slot:title>
                            <i class="uil uil-file-contract-dollar font-size-20"></i>
                            <span class="d-none d-sm-block">Advertisements</span>
                        </template>
                        <div class="row">
                            <TabSponsorAdvertisements :sponsor_id="this.sponsor_id" @onRefresh="getSponsor()" />
                        </div>
                    </b-tab>
                </b-tabs>
            </div>
        </div>
    </div>
    <!-- end row -->


    <!-- MODALS -->
    <modalEditSponsor :sponsorData="sponsorData" @onRefresh="getSponsor()"></modalEditSponsor>
    <modalAddSponsorAttributes :sponsor_id="sponsor_id" @refreshScreen="getSponsor"></modalAddSponsorAttributes>
    <modalAddSponsorMedia :sponsor_id="sponsor_id" @refreshScreen="getSponsor" ></modalAddSponsorMedia>
    <!-- END  MODALS -->
</Layout>
</template>
